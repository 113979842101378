<template>
    <div>
      <loading
        :active.sync="isLoading"
        background-color="#332abb"
        :opacity="0.99"
        color="#fff"
        :is-full-page="fullPage"
      ></loading>
  
      <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
      <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
      <ModalHediyeKarti ref="modalHediyeKarti"></ModalHediyeKarti>
      <modalGallery ref="modalGallery"></modalGallery>
      <MainBanner
        @clicked="showModal"
        Title="Çocukların Görünmeyen Yaralarını,<br> Vicdanlı Bilimle Saralım "
       
        ButtonText="Bağış Yap"
        OverlapImage="/svg/banner-bilim-seferberligi.svg"
        TextPosition="between"
        Height="middle"
      />
  
      <div class="main-continer deprem">
        <section class="container bilim-seti-info " id="bilim-seti">
          <div class="d-flex flex-column align-items-center">
            <p class="text-center"> 6-7 Şubat 2023 tarihlerinde gerçekleşen depremlerden <b>11</b> ilimizde <b>4.6 milyon</b> çocuk etkilendi. Okullar ve diğer eğitim altyapılarında meydana gelen geniş çaplı zararlar nedeniyle çocukların eğitimleri ve gelişimleri sekteye uğradı. YGA olarak bu ulusal felakette, tecrübemiz doğrultusunda en çok katkı sağlayabileceğimiz alanları tespit ederek barınma ve temel gereksinimleri sağlanmış çocukların, <b>oyunla öğrenme</b> üzerinden sosyal ve bilişsel olarak desteklenmelerine odaklandık.

<br><br>Deprem sonrasında belirsizlik, güvensizlik, içe kapanıklık ve karamsarlık ile mücadele eden çocuklarda, görünen yaraların dışında görünmeyen yaralar da oluştu. Çocukların dış dünyayı keşfe, olayları anlamlandırmaya başladıkları bu yaşlarda; onlara hayatları boyunca tutunabilecekleri güçlü bir dal vermek çok önemli.
</p>
<p class="text-center">Çocuklarda kontrol ve güven duygusunu yeniden inşa etmenin en verimli yollarından biri <b>oyunla öğrenme.</b>
                    <br><br>
                    Deprem sonrasında <b>uzman psikologlar</b> ve <b>bilim insanlarından</b> alınan eğitimler ile bilim atölyelerini ve içeriklerini, depremden etkilenen çocukların ihtiyaçlarına uygun olarak yeniledik ve geliştirdik.
                    <br><br>
                    Bilim atölyeleri, çocuklara elleriyle geliştirebilecekleri projeler üretme fırsatı veriyor. Sorunlarla başa çıkma becerilerini, problem çözme yetilerini ve özgüvenlerini geliştiriyor. Atölye sonrası <b>dijital entegrasyon</b> ile çocukların gelişimi destekleniyor.
                </p>
                <h4 class="mt-3">Hedefimiz</h4>
                <p class="mt-3">Depremden etkilenen <b>10-12 yaş</b> aralığındaki <b>1.1 Milyon</b> çocuğu vicdanlı bilimle buluşturmak.</p>
                <a href="https://fonzip.com/yga/fundraising-campaigns/depremzede-cocuklara-bilimi-sevdirelim--" target="_blank" class="button-rounded d-flex align-items-center justify-content-center mt-4 float-left blue">Bağış için tıklayın</a>
                <div class="col-md-8 mt-5">
              <iframe width="100%" height="415" src="https://www.youtube.com/embed/c2E6hlTIe6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" class="vimeo"></iframe>
            </div>
          </div>
        </section>
        <section class="container bilim-seti-info">
            <div class="d-flex flex-column flex-lg-row-reverse align-items-center justify-content-between">
                <div class="box box col-lg-5">
                    <div class="boxpan">
                        <h1>
                            Deprem Bölgelerinde <br> Neler Yapıyoruz?
                        </h1>

                        <div class="bilim-seti-img d-block d-lg-none"><img src="/svg/bilim-seti.svg" /></div>

                        <p>
                            Hedefimiz, depremden etkilenen 10-12 yaş aralığındaki 1.1 Milyon çocuğu vicdanlı bilimle buluşturmak.
                        </p>

                        <ul>
                          <li>Pasif değil <b>aktif</b> bir şekilde dahil olabilecekleri bilim atölyeleri yapıyoruz.</li>
                          <li>Sahada olan kurumlar, YGA mezunları, gönüllüleri ve şirket gönüllüleri ile bilim atölyelerinin <b>sürdürülebilirliğini</b> sağlıyoruz.</li>
                          <li><b>Prof. Acar Baltaş, Prof. Mehmet Toner</b> gibi konusunda uzman bilim insanı ve psikologlar ile sahaya özel moderasyon eğitimleri hazırladık. Tüm gönüllülerin eğitimini saha öncesinde gerçekleştiriyoruz.</li>
                        </ul>
                    </div>                   
                   
                </div>
                <div class="box-delayed bilim-seti-img d-none d-lg-block"><img class="bordrd" src="/img/gorsel00.jpg" /></div>
            </div>
        </section>
        <section
        class="parallax-section gray-section section-padding section-high"
        id="neden-bilim-seferberligi"
        >
          <div
            class="
              container
              d-flex
              flex-column-reverse flex-lg-row
              align-items-center
              justify-content-between
            "
          >
            <div class="text-center text-md-left col-md-5 ml-0" v-if="this.pageContent">
              <div>
                <h4 class="mb-3">
                  İnteraktif Sergi Alanı
                </h4>
                <p class="mb-3">
                  İnteraktif bilim müzesi konseptinde hazırlanan sergi ile depremden etkilenen çocuklar, bilim ve teknolojinin eğlenceli yüzüyle tanışır.
                </p>
                <h4 class="mb-3">
                  Bilim Atölyesi
                </h4>
                <p class="mb-3">
                  Çocukların kontrol ve güveni hissettikleri, psikologlarla hazırlanan interaktif bilim atölyesi yapılır. Bilim Gönüllüleri ile haftalık
                    düzenli olarak depremden etkilenen 10-12 yaş aralığındaki çocuklarla bilim atölyeleri gerçekleştirilir. Çocuklara dijital bilim üyeliği hediye edilir.
                </p>
                <h4 class="mb-3">
                  YGA Kampı, İstanbul
                </h4>
                <p class="mb-5 mb-md-0">
                  Bilim atölyelerinden ilgili olan çocuklar 2023 yazında İstanbul’da yapılacak Bilim Kampı’na davet edilir. Kamp sonrasında seçilen çocuklara mentörlük sağlanır.
                </p>

            </div>


              <img src="/img/untitled-1.png" class="d-block d-md-none" />
            </div>

            <div class="parallax-img-wrapper col-md-6 d-none d-md-flex align-items-center justify-content-center">
              <img class="prl-0" src="/img/parallax/02/okul-bagis.svg" />
              <img class="down-1 bordrd prl-1" src="/img/parallax/05/gorsel4.jpg" />
              <img class="down-2 bordrd prl-2" src="/img/parallax/05/gorsel5.jpg" />
              <img class="up bordrd prl-3" src="/img/parallax/05/gorsel6.jpg" />
            </div>
          </div>
        </section>
        <section
        class="parallax-section section-padding section-high"
        id="neden-bilim-seferberligi"
        >
          <div
            class="
              container
              d-flex
              flex-column-reverse flex-lg-row-reverse
              align-items-center
              justify-content-between
            "
          >
            <div class="text-center text-md-left col-md-5 ml-0" v-if="this.pageContent">
              <div>
                <h1 class="mb-3">
                  Bilim Atölyeleri Çocuklara Ne Kazandırıyor?
                </h1>
                <ul class="bold-text textlist mt-3 pl-0">
                  li>Depremden etkilenmiş birincil ihtiyaçları karşılanmış çocukların <b>iyi olma</b> haline destek olur.</li>
                    <li>Çocuklarda <b>post travma</b> döneminde, stresin etkilerini azalır; belirsizlikle baş edebilme becerileri gelişir.</li>
                    <li>Çocuklarda <b>merak duygusunu</b> tetiklenir.</li>
                    <li>Çocuklar, <b>bilim ve teknoloji</b> ile çevrelerinde gördükleri sorunlara <b>çözüm</b> geliştirebileceklerini keşfederler.</li>
                    <li>Çocuklara <b>geleceği hayal edebilecekleri</b> güvenli bir alan yaratılır.</li>
                    <li>Çocukların <b>özgüvenleri</b> desteklenir.</li>
                    <li>Çocuklar geleceğe <b>umutla</b> bakar.</li>
                </ul>


            </div>


              <img src="/img/untitled-1.png" class="d-block d-md-none" />
            </div>

            <div class="parallax-img-wrapper col-md-6 d-none d-md-flex align-items-center justify-content-center">
              <img class="prl-0" src="/img/parallax/02/okul-bagis.svg" />
              <img class="down-1 bordrd prl-1" src="/img/parallax/05/gorsel1.jpg" />
              <img class="down-2 bordrd prl-2" src="/img/parallax/05/gorsel2.jpg" />
              <img class="up bordrd prl-3" src="/img/parallax/05/gorsel3.jpg" />
            </div>
          </div>
        </section>
        <section class="section-high bgPurpleLight">
            <div class="container bagisSec notModal d-flex flex-column align-items-center justify-content-center h-100">
                <!---->
                <div class="text-center mb-5">
                    <h1><h1>Projenin Sürdürülebilirliğini Nasıl Sağlıyoruz?</h1></h1>
                    <p></p>
                </div>
                <div class="swiper-bagis-sec swiper-carousel position-relative swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                    <div class="swiper-wrapper d-flex flex-row flex-sm-column flex-md-row mb-5 deprm" style="transform: translate3d(0px, 0px, 0px);">
                        <div class="bagis-card swiper-slide d-flex flex-column align-items-center justify-content-start text-center swiper-slide-active" >
                            <!-- <div class="img-wrapper d-flex align-items-center justify-content-center"><img data-src="/svg/bagis-yap.svg" width="123" height="200" src="/svg/bagis-yap.svg" lazy="loaded" /></div> -->
                            <div class="w-100">
                                <h3>Öğretmenlerle Birlikteyiz:</h3>
                                <p>Deprem bölgesindeki okullarda görev yapan öğretmenler, özel hazırlanan bir dijital platforma ve eğitim programına dahil edilir. Bu platformda, öğretmenler bilim setlerini nasıl kullanacaklarını öğrenir; birçok geliştirici eğitime erişim sağlarlar. Bilim setlerini ders müfredatına dahil ederek öğrencileriyle düzenli olarak bilim atölyelerine devam ederler.</p>
                            </div>
                          </div>
                        <div class="bagis-card swiper-slide d-flex flex-column align-items-center justify-content-start text-center swiper-slide-next" >
                            <!-- <div class="img-wrapper d-flex align-items-center justify-content-center"><img data-src="/svg/kampanya-olustur.svg" width="190" height="153" src="/svg/kampanya-olustur.svg" lazy="loaded" /></div> -->
                            <div class="w-100">
                                <h3>Sivil Toplum Kuruluşlarıyla Birlikteyiz:</h3>
                                <p>Deprem bölgesinde aktif çalışmalar yapan STK gönüllülerine, konusunda uzman bilim insanı ve psikologlar ile sahaya özel hazırlanan moderasyon eğitimleri verilir. Gönüllüler, projenin daha fazla çocuğa, sürdürülebilir şekilde ulaşması için bilim atölyelerini düzenli şekilde yürütür. Çalışma çıktılarını, Bilim Seferberliği ekibi ile paylaşır.</p>
                            </div>
                          </div>
                        <div class="bagis-card swiper-slide d-flex flex-column align-items-center justify-content-start text-center" style="width: 523.333px;">
                            <!-- <div class="img-wrapper d-flex align-items-center justify-content-center"><img data-src="/svg/hediye-karti-gonder.svg" width="190" height="97" src="/svg/hediye-karti-gonder.svg" lazy="loaded" /></div> -->
                            <div class="w-100">
                                <h3>Şirketlerle Birlikteyiz:</h3>
                                <p>Deprem bölgesindeki çocukları desteklemek isteyen şirketlerin gönüllüleri, bilim atölyelerinde YGA gönüllülerine eşlik ederler. Bilim atölyeleri öncesinde şirket gönüllülerine sahaya özel hazırlanan moderasyon eğitimleri verilir.</p>
                            </div>
                          </div>
                    </div>
                    <div class="d-flex d-md-none justify-content-center">
                        <div class="el-pag w-100 d-flex align-items-center justify-content-center swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-padding quotes">
          <div class="swiper-quotes">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                        <div class="container text-center">
                            <div class="quotes-text d-flex flex-column flex-md-row align-items-center">
                            <span class="svg-icon icons-quotesLeft"></span>
                            <p>Bilim göz ardı edildiği için bu felaketleri yaşadık. Tekrar yaşanmamasını için, çocukları bilimle buluşturmak , geleceğe yapılacak en doğru yatırımdır.</p>
                            <span class="svg-icon icons-quotesRight"></span>
                            </div>
                            <div class="quotes-profile">
                            <h3 class="name">Mehmet Toner, Harvard & MIT</h3>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="container text-center">
                            <div class="quotes-text d-flex flex-column flex-md-row align-items-center">
                            <span class="svg-icon icons-quotesLeft"></span>
                            <p>Kısa süreli çözümler yerine uzun dönemde sorunları önleyecek alanlara yatırım yapmak sivil iradenin kendini hissettirmesiyle mümkündür. Ruh sağlığını korumanın en önemli adımı “anlamlı bir üretim içinde olmaktır.</p>
                            <span class="svg-icon icons-quotesRight"></span>
                            </div>
                            <div class="quotes-profile">
                            <h3 class="name">Prof. Dr. Acar Baltaş, Psikolog</h3>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="swiper-pagg d-flex w-100 justify-content-center"></div>
          </div>
        </section>
        <div class="container mb-5">
          <div class="text-center"><p>YGA 20 yılı aşkın süredir <b>50</b> farklı ilde <b>614.000</b> çocuğa vicdanlı bilimi ulaştırmıştır. Tecrübeli yüzlerce rol model öğretmen ve YGA gönülleriyle 50 farklı ilde Suriyeli mülteci çocuklar, İzmir depreminden etkilenen çocuklar ve Anadolu’nun dört bir yanında <b>imkanları sınırlı çocuklarla</b> bilim atölyeleri gerçekleşmiştir.

<br><br><b>IPSOS’la</b> yapılan değerlendirme çalışmasına göre öğretmenlerin <b>%97’si</b> bilim setlerinin derslere olumlu etkisi olduğunu düşünürken, <b>%89’u</b> ise bilim setleri uygulamasının kendi akademik yaklaşımına uygun bulmuştur.
</p>
<img class="my-5 ips" src="/img/ipsos.png" alt="ipsos"></div>
        </div>
        <div class="  mb-5">
        </div>
        <div class="text-center"><h3 class="font-orange my-5">Nasıl Destek Olabilirim?</h3></div>
        <div class="  mb-5">
        </div>
        <div class="container flex-md-row flex-column d-flex align-items-start justify-content-between my-mid mb-5 accounts">
            <div class="col-md-3">
                <h4 class="mb-3">TL Bağış</h4>
                <p>
                    Hesap Adı: Hayal Ortakları Derneği <br />
                    TR71 0006 4000 0011 2260 4303 74 <br />
                    Açıklama: Bilim Atölyesi
                </p>
            </div>
            <div class="col-md-3">
                <h4 class="mb-3">USD Bağış</h4>
                <p>
                    TÜRKİYE İŞ BANKASI <br />
                    HAYAL ORTAKLARI DERNEĞİ <br />
                    Bank BIC: ISBKTRISXXX <br />
                    TR59 0006 4000 0021 2260 2896 44
                </p>
            </div>
            <div class="col-md-3">
                <h4 class="mb-3">EURO Bağış</h4>
                <p>
                    TÜRKİYE İŞ BANKASI <br />
                    HAYAL ORTAKLARI DERNEĞİ <br />
                    Bank BIC: ISBKTRISXXX <br />
                    TR61 0006 4000 0021 2260 3879 66
                </p>
            </div>
            <div class="col-md-3">
                <h4 class="mb-3">GBP Bağış</h4>
                <p>
                    TÜRKİYE İŞ BANKASI <br />
                    HAYAL ORTAKLARI DERNEĞİ <br />
                    Bank BIC: ISBKTRISXXX <br />
                    TR70 0006 4000 0021 2260 3998 32
                </p>
            </div>
        </div>
        <div class="container">
          <h4>Kredi Kartı ile Online Bağış:</h4>
            <a class="mt-3 d-block" href="https://fonzip.com/yga/fundraising-campaigns/depremzede-cocuklara-bilimi-sevdirelim--" target="_blank"><u><b>Depremzede Çocukların Yaralarını Bilimle Saralım! | YGA | Fonzip</b></u></a>
            <p class="mt-3">Bilim Gönüllüsü olmak için <a href="mailto:didem.cevik@yga.org.tr"><u><b>didem.cevik@yga.org.tr</b></u></a> (Didem Çevik, Bilim Seferberliği Proje Lideri) adresine e-posta atarak iletişime geçebilirsiniz.</p>
            <h4 class="mt-4">Kurumsal Bağış ve Destek:</h4>
            <p class="mt-3 mb-5">Kurumsal iş birlikleri, bağışlar ve fonlar için <a href="mailto:ahmet.tonel@yga.org.tr"><u><b>ahmet.tonel@yga.org.tr</b></u></a>  (Ahmet Tonel, Kaynak Geliştirme Direktörü) adresine e-posta atarak iletişime geçebilirsiniz.</p>
        </div>
        <div class="spacer  mb-5">
        </div>
       
  
        <FooterLanding />
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  var VueScrollTo = require("vue-scrollto");
  import { gsap } from "gsap";
  import { ScrollTrigger } from "gsap/ScrollTrigger";
  gsap.registerPlugin(ScrollTrigger);
  
  import {
    Swiper,
    Scrollbar,
    EffectCoverflow,
    SwiperClass,
    Pagination,
    Navigation,
    Mousewheel,
    Autoplay,
    EffectFade,
  } from "swiper";
  Swiper.use([
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow,
    Mousewheel,
    Autoplay,
    EffectFade,
  ]);
  import "swiper/swiper-bundle.css";
  
  import ButtonRounded from "@/components/ButtonRounded";
  import MainBanner from "@/components/MainBanner";
  import FooterLanding from "@/components/FooterLanding";
  import ModalBagisYap from "@/components/ModalBagisYap";
  import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
  import ModalHediyeKarti from "@/components/ModalHediyeKarti";
  import BagisSec from "@/components/BagisSec";
  import FloatingCard from "@/components/FloatingCard";
  import ToplananBagisInfographic from "@/components/ToplananBagisInfographic";
  import MultipleCarousel from "@/components/MultipleCarousel";
  import ModalGallery from "@/components/ModalGallery";
  export default {
    name: "BilimSeferberligi",
    components: {
      ButtonRounded,
      MainBanner,
      FooterLanding,
      ModalBagisYap,
      Loading,
      BagisSec,
      FloatingCard,
      ToplananBagisInfographic,
      ModalKampanyaOlustur,
      ModalHediyeKarti,
      MultipleCarousel,
      ModalGallery,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        bagisSecTitle: "",
        bagisSecDesc: "",
        teacherData: {},
        studentData: {},
        newsData: {},
        mainSupporters: {},
        infographic: "",
        pageContent: ''
      };
    },
      metaInfo() {
      return {
        title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
        meta: [
          {
            vmid: "description",
            name: "description",
            //content: this.metaData.description,
          },
          // {name: 'keywords', content: this.meta_data.meta_keywords},
          // {property: 'og:image', content: this.meta_data.meta_image},
          { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
          { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
          { property: "og:url", content: window.location.href },
          { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
          { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
          { name: "twitter:url", content: window.location.href},
          // {name: 'twitter:image', content: this.meta_data.meta_image},
          // {property: 'og:image:width', content: '540'},
          // {property: 'og:image:height', content: '282'},
        ],
      };
    },
    mounted() {
      function scrollToAnchor(url) {
        var urlHash = url.split("#")[1];
        if (urlHash && $("#" + urlHash).length) {
          $("html").animate(
            {
              scrollTop: $("#" + urlHash).offset().top - 60,
            },
            500
          );
        }
      }
  
      $(document).ready(function () {
        scrollToAnchor(window.location.href);
      });
  
      $(".same-links a").click(function (event) {
        event.preventDefault(); // stop the browser from snapping to the anchor
        scrollToAnchor(event.target.href);
      });
      this._swiperInit();
      // this._floatingParallax();
      this._scrollParallax();
      this.getStories();
      this.getNews();
      this.getSupporters();
      this.getInfographic();
      if (this.$router.currentRoute["hash"]) {
        VueScrollTo.scrollTo(this.$router.currentRoute["hash"], 500);
      }
      this.getPageContent("bilim-seferberligi");
    },
    beforeRouteUpdate(to, from, next) {
      if (this.$router.currentRoute["hash"]) {
        VueScrollTo.scrollTo(this.$router.currentRoute["hash"], 500);
      }
      this._swiperInit();
    },
    methods: {
      getPageContent(page) {
        this.$api.getPageContent(page).then((response) => {
          this.pageContent = response;
          this.bagisSecTitle = this.pageContent.page[2].content.replace("</h1>", "</h1>#").split("#")[0];
          this.bagisSecDesc = this.pageContent.page[2].content.replace("</h1>", "</h1>#").split("#")[1];
        });
      },
      getInfographic() {
        this.$api.getInfographic().then((response) => {
          this.infographic = response;
        });
      },
      showModal() {
        this.$refs.modalBagisYap.openModal();
        this.$refs.modalBagisYap.getSwiper();
      },
      modalKampanyaOlustur() {
        this.$refs.modalKampanyaOlustur.openModal();
        this.$refs.modalKampanyaOlustur.getSwiper();
      },
      showModalGallery() {
        this.$refs.modalGallery.openModal();
      },
      getStories() {
        this.$api.getStories().then((response) => {
          this.teacherData = response.teacher;
          this.studentData = response.student;
        });
      },
      getNews() {
        this.$api.getNews().then((response) => {
          this.newsData = response;
        });
      },
      modalHediyeKarti() {
        this.$refs.modalHediyeKarti.openModal();
        this.$refs.modalHediyeKarti.getSwiper();
      },
      getSupporters() {
        this.$api.getSupporters().then((response) => {
          this.mainSupporters = response.advisory_board;
  
          setTimeout(() => {
            this._swiperInit();
          }, 300);
        });
      },
      _swiperInit() {
        const swiperQ = new Swiper(
        ".swiper-quotes",
        {
          spaceBetween: 0,
             allowTouchMove: true,
    paginationClickable: true,
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagg",
            clickable: true,
          },
          breakpoints: {
            567: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          },
        }
      );
        const swiperBasinda = new Swiper(".swiper-basinda-bilimseferberligi", {
          loop: false,
          spaceBetween: 30,
          centeredSlides: false,
          navigation: {
            nextEl: ".swiper-basinda-bilimseferberligi .swiper-button-next",
            prevEl: ".swiper-basinda-bilimseferberligi .swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          
          breakpoints: {
            567: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 4,
            },
          },
        });
        const swiperBilimSenlikleri = new Swiper(".swiper-destekciler", {
          //slidesPerView: 5,
          spaceBetween: 20,
          // pagination: {
          //   el: '.swiper-pagination',
          // },
          breakpoints: {
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          },
          // pagination: {
          //   el: ".swiper-pagination",
          // },
        });
      },
      _scrollParallax() {
        gsap.utils.toArray(".parallax-img-wrapper").forEach((section, index) => {
          const down1 = section.querySelector(".down-1");
          const down2 = section.querySelector(".down-2");
          const up = section.querySelector(".up");
          const x = section.querySelector(".parallax-img-wrapper");
  
          gsap.to(down1, {
            scrollTrigger: {
              trigger: section,
              // markers:true,
              start: "top 80%",
              end: "bottom 20%",
              scrub: 1,
              // toggleActions: "play reset play complete",
              // ease:'linear'
            },
            y: 100,
            x: 20,
          });
  
          gsap.to(down2, {
            scrollTrigger: {
              trigger: section,
              // markers:true,
              scrub: 1,
              start: "top 80%",
              end: "bottom 20%",
            },
            y: 75,
            x: -20,
          });
  
          gsap.to(up, {
            scrollTrigger: {
              trigger: section,
              // markers:true,
              scrub: 1,
              start: "top 80%",
              end: "bottom 20%",
            },
            y: -75,
            x: 30,
          });
        });
      },
    },
  };
  </script>
  <style lang="scss">
  .mw-70 {
    max-width: 70%;
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
  }
  @media (max-width: 567px) {
    h4 {
      font-size: 22px;
      font-weight: 600;
    }
    .swiper-basinda-bilimseferberligi {
      .logo-wrapper {
        img {
          max-width: 100%;
        }
      }
      h3 {
        font-size: 22px;
        font-weight: 500;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .deprm{
    .bagis-card{
      cursor: default !important;
        .img-wrapper{
          max-height: 200px;
        }
    }
  }
  .bordrd{
    border-radius: 2rem;
  }
  @media (max-width:768px) {
    .deprem{
      iframe{
       max-height: 200px;
      }
      .accounts{
        text-align: center;
        h4{
          margin-top: 1rem;
        }
      }
    }
  }
  .ips{
    width: 10vw;
    @media (max-width: 768px) {
        width: 20vw;
    }
        
    
}
  
  </style>